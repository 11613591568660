<template>
  <div>
    <h3>Mijn organisatie</h3>
    <p v-if="isMainContact">
      Indien deze gegevens niet correct zijn, gelieve de hoofdverantwoordelijke
      te contacteren. Enkel de hoofdverantwoordelijke kan de gegevens van de
      organisatie wijzigen.
    </p>
    <p v-if="!isMainContact">
      De hoofdverantwoordelijke is de enige die de gegevens van de organisatie
      kan wijzigen. Contactpersonen kunnen de gegevens bekijken, niet wijzigen.
    </p>

    <b-form @submit.prevent="updateCustomer">
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-customer-name"
            label="Naam organisatie"
            label-for="input-customer-name"
          >
            <b-form-input
              id="input-customer-name"
              v-model="form.CompanyName"
              type="text"
              placeholder="Naam organisatie"
              :disabled="isMainContact"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-customer-organisation-number"
            label="Ondernemeningsnummer"
            label-for="input-customer-organisation-number"
          >
            <b-form-input
              id="input-customer-organisation-number"
              v-model="form.CustomFields.CUST_EnterpriseNumber"
              type="text"
              :disabled="isMainContact"
              placeholder="Ondernemingsnummer voor eventuele facturatie"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="12">
          <b-form-group
            id="input-group-customer-description"
            label="Omschrijving van de organisatie"
            label-for="input-customer-description"
          >
            <b-form-textarea
              id="input-customer-description"
              v-model="form.CustomFields.CUST_Comments"
              placeholder="Omschrijf kort waar de organisatie voor staat"
              rows="3"
              :disabled="isMainContact"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-customer-website"
            label="Website"
            label-for="input-customer-website"
          >
            <b-form-input
              id="input-customer-website"
              v-model="form.Website"
              type="text"
              :disabled="isMainContact"
              placeholder="Website"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-customer-CUST_SocialMedia"
            label="Socialmedia"
            label-for="input-customer-CUST_SocialMedia"
          >
            <b-form-input
              id="input-customer-CUST_SocialMedia"
              v-model="form.CustomFields.CUST_SocialMedia"
              type="text"
              :disabled="isMainContact"
              placeholder="Socialmedia"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="12">
          <b-form-group
            id="input-group-customer-email"
            label="E-mailadres"
            label-for="input-customer-email"
          >
            <b-form-input
              id="input-customer-email"
              v-model="form.Email"
              type="text"
              :disabled="isMainContact"
              placeholder="E-mailadres"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-street"
            label="Straat"
            label-for="input-street"
          >
            <b-form-input
              id="input-street"
              v-model="form.Street"
              type="text"
              :disabled="isMainContact"
              placeholder="Straat"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="6" md="3">
          <b-form-group
            id="input-group-housenumber"
            label="Huisnummer"
            label-for="input-housenumber"
          >
            <b-form-input
              id="input-housenumber"
              v-model="form.HouseNumber"
              type="text"
              :disabled="isMainContact"
              placeholder="Huisnummer"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="6" md="3">
          <b-form-group
            id="input-group-housenumberaddition"
            label="Toevoeging"
            label-for="input-housenumberaddition"
          >
            <b-form-input
              id="input-housenumberaddition"
              v-model="form.HouseNumberAddition"
              type="text"
              :disabled="isMainContact"
              placeholder="Toevoeging"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-postal"
            label="Postcode"
            label-for="input-postal"
          >
            <b-form-input
              id="input-postal"
              v-model="form.ZipCode"
              type="text"
              :disabled="isMainContact"
              placeholder="Postcode"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-city"
            label="Stad"
            label-for="input-city"
          >
            <b-form-input
              id="input-city"
              v-model="form.City"
              type="text"
              :disabled="isMainContact"
              placeholder="Stad"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>

        <hr />
        <b-col cols="12">
          <h3>Gegevens van de hoofdverantwoordelijke</h3>
          <table class="table">
            <tr>
              <td>Voornaam</td>
              <td>{{ mainUser.FirstName || 'Onbekend' }}</td>
            </tr>
            <tr>
              <td>Achternaam</td>
              <td>{{ mainUser.LastName || 'Onbekend' }}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{{ mainUser.Email || 'Onbekend' }}</td>
            </tr>
            <tr>
              <td>Mobiel</td>
              <td>{{ mainUser.MobilePhone || 'Onbekend' }}</td>
            </tr>
            <tr>
              <td>Telefoon</td>
              <td>{{ mainUser.Tel || 'Onbekend' }}</td>
            </tr>
          </table>
        </b-col>

        <b-col cols="12">
          <b-button
            v-if="!isMainContact"
            class="mb-2"
            type="submit"
            variant="primary"
            >Gegevens bijwerken</b-button
          >
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: {
        CustomFields: {}
      }
    }
  },
  computed: {
    account: function() {
      return this.$store.getters.account
    },

    isMainContact: function() {
      return this.$store.getters.account.RoleName === 'portal'
    },
    mainUser() {
      return this.$store.getters.getMainUser ?? {}
    },
    customer() {
      return this.$store.getters['customer/getCustomer']
    }
  },
  watch: {
    customer(customer) {
      this.form = customer
    }
  },
  created: function() {
    this.form = this.customer
  },
  methods: {
    async updateCustomer() {
      await this.$store.commit('customer/setCustomer', this.form)
      await this.$store.dispatch('customer/patchCustomer')
      this.$toasted.show('Gegevens succesvol aangepast')
    }
  }
}
</script>
