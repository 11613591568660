<template>
  <div>
    <h3>Mijn reserveringen</h3>

    <b-table
      v-if="reservations.length > 0"
      :items="reservations"
      :fields="fields"
      responsive
    >
      <template v-slot:cell(Status)="data">
        <span
          >{{
            reservationStatus(
              data.item.ReservationStatus,
              data.item.WarehouseID
            )
          }}<br />
          <small>{{
            paymentStatus({
              isPayed: data.item.CustomFields.CUST_IsPaid,
              onInvoice: data.item.CustomFields.CUST_OnInvoice
            })
          }}</small></span
        >
      </template>
      <template v-slot:cell(ReservationID)="data">
        <router-link
          :to="{
            name: 'reservation',
            params: { reservationID: data.item.ReservationOrderID }
          }"
          >#{{ data.item.ReservationOrderID }}</router-link
        >
      </template>
      <template v-slot:cell(TotalIncVAT)="data">
        <div class="form-group text-right">
          &euro;
          {{
            (Math.round(data.item.TotalIncVAT * 100) / 100)
              .toFixed(2)
              .replace('.', ',')
          }}
        </div>
      </template>

      <template v-slot:cell(DateTimeBusinessStart)="data">
        {{ data.item.DateTimeBusinessStart | moment('DD/MM/YYYY') }}
        <br />
        {{ data.item.DateTimeExpectedEnd | moment('DD/MM/YYYY') }}
      </template>
      <template v-slot:cell(DateTimeExpectedEnd)="data"> </template>
      <template v-slot:cell(ContactFirstName)="data">
        {{ data.item.ContactFirstName }} {{ data.item.ContactLastName }}
      </template>

      <template v-slot:cell(ActivityDescription)="data">
        <div
          v-b-tooltip.hover
          :title="stripHtml(data.item.ActivityDescription)"
        >
          {{ formatActivityDescriptionString(data.item.ActivityDescription) }}
        </div>
      </template>

      <template v-slot:cell(dinges)="data">
        <reservation-actions
          :reservation="data.item"
          @reservationCancelled="updateReservations"
        />
      </template>
    </b-table>
    <p v-else>
      U heeft nog geen reserveringen om te bekijken. Wanneer u een nieuwe
      reservering aanmaakt zal deze hier worden getoond.
    </p>
  </div>
</template>

<script>
import ReservationActions from '@/components/Reservation/ReservationActions.vue'

import { getReservations } from '@/services/ReservationService'

export default {
  components: {
    ReservationActions
  },
  data() {
    return {
      reservations: [],
      fields: [
        {
          key: 'ReservationID',
          label: 'Aanvraag'
        },
        {
          key: 'Status',
          label: 'Status'
        },
        {
          key: 'TotalIncVAT',
          label: 'Totaal',
          class: 'text-right no-break '
        },
        {
          key: 'WarehouseID',
          label: 'Locatie'
        },
        {
          key: 'DateTimeBusinessStart',
          label: 'Verhuurperiode'
        },
        {
          key: 'ReservationOrderID',
          label: '',
          class: 'd-none'
        },
        {
          key: 'AttachmentToken',
          label: '',
          class: 'd-none'
        },
        {
          key: 'CustomFields.CUST_OnInvoice',
          class: 'd-none'
        },
        {
          key: 'CustomFields.IsPaid',
          class: 'd-none'
        },
        {
          key: 'ContactDescription',
          label: 'Gereserveerd door'
        },
        {
          key: 'ActivityDescription',
          label: 'Activiteit'
        },
        {
          key: 'dinges',
          label: 'Acties'
        }
      ]
    }
  },
  created: async function() {
    await this.updateReservations()
    this.$store.commit('setReservations', { reservations: this.reservations })
  },
  methods: {
    async updateReservations() {
      this.reservations = await getReservations()
      this.reservations.forEach(reservation => {
        reservation.ReportID = reservation.CustomFields.CUST_ReportID
        reservation.ContactDescription =
          reservation.CustomFields.CUST_ContactDescription
        reservation.ActivityDescription =
          reservation.CustomFields.CUST_ActivityDescription
      })
    },
    paymentStatus: function({ isPayed, onInvoice }) {
      if (isPayed) return 'Betaald'
      if (onInvoice) return 'Op factuur'
      return 'Nog niet betaald'
    },
    reservationStatus: function(status) {
      if (status === 'Active' || status === 'Open' || status === 'Actief')
        return 'Aangevraagd'

      if (status === 'Order') return 'Reservering bevestigd'

      if (status === 'Pick' || status === 'Picked') return 'In behandeling'

      if (status === 'Pending') return 'Uitgeleend'

      if (status === 'Closed') return 'Ingeleverd'

      if (status === 'Cancelled' || status === 'Expired') return 'Geannuleerd'

      if (status === 'Rejected') return 'Afgewezen'

      return 'Aangevraagd'
    },
    formatActivityDescriptionString(ActivityDescription) {
      if (ActivityDescription.length > 50) {
        return ActivityDescription.substring(0, 50) + '...'
      }
      return ActivityDescription
    },
    stripHtml(html) {
      let tmp = document.createElement('DIV')
      tmp.innerHTML = html
      return tmp.textContent || tmp.innerText || ''
    }
  }
}
</script>
