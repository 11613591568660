import { render, staticRenderFns } from "./Reservations.vue?vue&type=template&id=0b9a8ee9&"
import script from "./Reservations.vue?vue&type=script&lang=js&"
export * from "./Reservations.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/builds/rentmagic/rentmagic.website.vue.west-vlaanderen/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BTable} from 'bootstrap-vue'
    installComponents(component, {BTable})
    


    import installDirectives from "!/builds/rentmagic/rentmagic.website.vue.west-vlaanderen/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBTooltip} from 'bootstrap-vue'
    installDirectives(component, {'b-tooltip': VBTooltip})
    

export default component.exports