<template>
  <div>
    <b-form @submit.prevent="updateSub">
      <h4>Je wachtwoord wijzigen</h4>

      <b-row>
        <b-col sm="12" lg="10">
          <b-row>
            <b-col cols="12">
              <b-form-text id="password-help-block">
                <p>Een nieuwe wachtwoord moet aan de volgende eisen voldoen:</p>
                <ul>
                  <li>- Minimaal 8 karakters zijn.</li>
                  <li>- Met minimaal één hoofdletter. A t/m Z.</li>
                  <li>- Met minimaal één kleine letter. a t/m z.</li>
                  <li>- Met minimaal één cijfer. 0 t/m 9.</li>
                  <li>- Met minimaal één speciale teken, keuze uit !@$%&</li>
                </ul>
              </b-form-text>
            </b-col>
            <b-col cols="6" class="pb-3">
              <label>
                Nieuwe wachtwoord
                <span class="text-danger"></span>
              </label>
              <b-input-group>
                <b-form-input
                  id="input-password"
                  v-model="form.password"
                  :type="showPassword ? 'text' : 'password'"
                  :state="passwordValidation"
                  placeholder="Wachtwoord"
                  name="password"
                  required
                  min="8"
                  max="32"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    title="Wachtwoord tonen"
                    variant="primary"
                    @click="showPasswordToggle()"
                  >
                    <font-awesome-icon v-if="!showPassword" fas icon="eye" />
                    <font-awesome-icon
                      v-if="showPassword"
                      fas
                      icon="eye-slash"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col cols="6">
              <label>
                Herhaal wachtwoord
                <span class="text-danger"></span>
              </label>
              <b-input-group>
                <b-form-input
                  id="input-passwordConfirm"
                  v-model="form.confirmPassword"
                  :type="showPassword ? 'text' : 'password'"
                  :state="passwordConfirmState && passwordValidation"
                  name="confirmPassword"
                  placeholder="Vul uw wachtwoord nogmaals in"
                  required
                  min="8"
                  max="32"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    title="Wachtwoord tonen"
                    variant="primary"
                    @click="showPasswordToggle()"
                  >
                    <font-awesome-icon v-if="!showPassword" fas icon="eye" />
                    <font-awesome-icon
                      v-if="showPassword"
                      fas
                      icon="eye-slash"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col cols="12">
              <b-button
                variant="primary"
                class="float-right"
                type="submit"
                :disabled="!passwordConfirmState || !passwordValidation"
              >
                Wachtwoord opslaan
                <font-awesome-icon
                  v-if="processing"
                  class="fa-spin"
                  far
                  :icon="['fad', 'spinner']"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { modifySub } from '@/services/AuthorizationService'
export default {
  data() {
    return {
      form: {
        email: '',
        password: null,
        confirmPassword: null,
        street: '',
        city: '',
        houseNumber: '',
        postcode: '',
        tel: '',
        firstname: '',
        lastname: ''
      },
      registering: false,
      formFilled: false,
      processing: false,
      subUser: {},
      showPassword: false
    }
  },
  computed: {
    subUsers: function() {
      return this.$store.getters.subUsers
    },
    userName: function() {
      return this.$store.getters.user.UserName
    },
    contactID: function() {
      return this.$store.getters.user.ContactID
    },
    passwordConfirmState() {
      if (this.form.password != '' && this.form.password != null) {
        if (this.form.password == this.form.confirmPassword) {
          return true
        }
        return false
      }
      return null
    },
    passwordValidation() {
      let password = this.form.password
      if (password != '' && password != null) {
        if (password.length < 8) {
          return false
        }

        if (!password.match(/[!@#$%&]/)) return false
        if (!password.match(/[0-9]/)) return false
        if (!password.match(/[a-z]/)) return false
        if (!password.match(/[A-Z]/)) return false
        if (!/^([0-9a-zA-Z!@#$%&]*)$/.exec(password)) return false

        return true
      }

      return null
    }
  },
  async created() {
    this.subUser = this.subUsers.find(obj => {
      return obj.UserName === this.$route.params.username
    })
  },
  methods: {
    showPasswordToggle: function() {
      if (this.showPassword) {
        this.showPassword = false
      } else {
        this.showPassword = true
      }
    },
    notify({ message }) {
      this.$bvToast.toast(message, {
        autoHideDelay: 5000,
        appendToast: false,
        title: 'success!',
        toaster: 'b-toaster-bottom-right'
      })
    },
    updateSub: async function() {
      this.processing = true
      // if (Object.values(this.form).some(x => x !== null && x !== '')) {
      //   alert('not all fields are filled')
      // }
      const result = await modifySub({
        password: this.form.password,
        confirmPassword: this.form.confirmPassword,
        userName: this.userName,
        contactID: this.contactID
      })

      if (!result.Message) {
        this.form.password = null
        this.form.confirmPassword = null
        this.$toasted.show('Wachtwoord succesvol bijgewerkt')
      }
      this.processing = false
    }
  }
}
</script>

<style></style>
