<template>
  <div>
    <h3>Mijn gegevens</h3>
    <b-form @submit.prevent="updateSub">
      <b-row>
        <b-col sm="12" lg="10">
          <b-row>
            <b-col cols="12" class="pb-3">
              <label>
                Voornaam
                <span class="text-danger">*</span>
              </label>
              <b-form-input v-model="form.firstname" required></b-form-input>
            </b-col>
            <b-col cols="12" class="pb-3">
              <label>
                Achternaam
                <span class="text-danger">*</span>
              </label>
              <b-form-input v-model="form.lastname" required></b-form-input>
            </b-col>
            <b-col cols="12" class="pb-3">
              <label>
                E-mailadres
                <span class="text-danger">*</span>
              </label>
              <b-form-input v-model="form.email" required></b-form-input>
            </b-col>
            <b-col cols="12" class="pb-3">
              <label>
                Telefoonnummer
                <span class="text-danger">*</span>
              </label>
              <b-form-input
                v-model="form.tel"
                oninput="javascript: this.value = this.value.replace(/[A-Za-z]/gi, '');"
                required
              ></b-form-input>
            </b-col>
            <b-col cols="12">
              <label>
                Gsm
                <span class="text-danger">*</span>
              </label>
              <b-form-input
                v-model="form.MobilePhone"
                oninput="javascript: this.value = this.value.replace(/[A-Za-z]/gi, '');"
                required
              ></b-form-input>
            </b-col>
            <b-col>
              <b-form-checkbox
                id="receive-newsletter"
                v-model="form.CUST_ReceiveNewsletter"
                name="receive-newsletter"
                value="accepted"
                unchecked-value="not_accepted"
                class="mt-3"
              >
                Ik ontvang graag de nieuwsbrief van de provinciale uitleendienst
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="text-right mt-3">
              <b-button variant="primary" type="submit">
                Wijzigingen opslaan
                <font-awesome-icon
                  v-if="processing"
                  class="fa-spin"
                  far
                  :icon="['fad', 'spinner']"
                />
              </b-button>
              <hr />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
    <change-password />
  </div>
</template>
<script>
import { modifySub, getSubs } from '@/services/AuthorizationService'
import ChangePassword from './ChangePassword'
export default {
  components: {
    'change-password': ChangePassword
  },
  props: {
    user: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: {
        username: '',
        firstname: '',
        lastname: '',
        tel: '',
        MobilePhone: '',
        password: '',
        passwordConfirm: '',
        CUST_ReceiveNewsletter: false
      },
      subUser: null,
      processing: false
    }
  },
  computed: {
    subUsers: function() {
      return this.$store.getters.subUsers
    }
  },
  created: async function() {
    await getSubs()
    this.setFormData(this.user)

    this.subUser = this.subUsers.find(obj => {
      return obj.UserName === this.$store.getters.account.Email
    })

    this.form = {
      email: this.subUser.Email || '',
      street: this.subUser.CUST_Street,
      city: this.subUser.CUST_City,
      houseNumber: this.subUser.CUST_HouseNumber,
      postcode: this.subUser.CUST_ZipCode,
      tel: this.subUser.Tel,
      firstname: this.subUser.FirstName,
      lastname: this.subUser.LastName,
      MobilePhone: this.subUser.MobilePhone,
      CUST_ReceiveNewsletter: this.$store.getters.account.CUST_ReceiveNewsletter
        ? 'accepted'
        : 'declined'
    }
  },
  methods: {
    setFormData: function(data) {
      this.form.username = data.UserName
      this.form.firstname = data.FirstName
      this.form.lastname = data.LastName
      this.form.tel = data.Tel
      this.form.MobilePhone = data.MobilePhone
      this.form.email = data.EmailAddress
    },
    notify({ message }) {
      this.$bvToast.toast(message, {
        autoHideDelay: 5000,
        appendToast: false,
        title: 'success!',
        toaster: 'b-toaster-bottom-right'
      })
    },
    updateSub: async function() {
      this.processing = true
      // if (Object.values(this.form).some(x => x !== null && x !== '')) {
      //   alert('not all fields are filled')
      // }

      const result = await modifySub({
        email: this.form.email || '',
        password: this.form.password,
        confirmPassword: this.form.confirmPassword,
        street: this.form.street,
        city: this.form.city,
        houseNumber: this.form.houseNumber,
        postcode: this.form.postcode,
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        contactID: this.subUser.ContactID,
        userName: this.subUser.UserName,
        tel: this.form.tel,
        MobilePhone: this.form.MobilePhone,
        CUST_ReceiveNewsletter: this.form.CUST_ReceiveNewsletter === 'accepted'
      })

      if (result !== null) this.$toasted.show('Gegevens succesvol aangepast')
      this.processing = false
    }
  }
}
</script>
