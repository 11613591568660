<template>
  <b-container>
    <bread-crumbs currentpage="Mijn account" />

    <b-row>
      <b-col>
        <h1>Mijn account</h1>
      </b-col>
      <b-col class="d-none d-md-block">
        <div class="h-100">
          <p class="text-right">
            <a href="/">Terug naar de catalogus</a>
          </p>
        </div>
      </b-col>
    </b-row>

    <p>Op de account pagina vind u uw account gegevens en reserveringen</p>

    <b-alert v-if="alertInfoMessage" show variant="danger">{{
      alertInfoMessage
    }}</b-alert>
    <div class="account-tabs">
      <b-tabs pills card>
        <b-tab title="Mijn gegevens" active>
          <User :user="user" />
        </b-tab>
        <b-tab title="Organisatie">
          <Organisation :user="user" />
        </b-tab>
        <b-tab title="Reserveringen">
          <Reservations />
        </b-tab>
        <b-tab title="Contactpersonen">
          <Contact />
        </b-tab>
      </b-tabs>
    </div>
  </b-container>
</template>
<script>
import User from '@/views/Account/User'
import Organisation from '@/views/Account/Organisation'
import Reservations from '@/views/Account/Reservations'
import Contact from '@/views/Account/Contact'

export default {
  components: {
    User,
    Organisation,
    Reservations,
    Contact
  },
  metaInfo: {
    title: `Mijn account`
  },
  computed: {
    user: function() {
      return this.$store.getters.user
    },
    alertInfoMessage: function() {
      if (this.$route.query.message) {
        this.$toasted.show(this.$route.query.message)
        return
      }
      if (this.$route.query.infoMessage) return this.$route.query.infoMessage
      return null
    }
  },
  created: function() {
    if (this.$route.params.loggedIn) {
      this.$toasted.show('Succesvol ingelogd')
    }
    if (this.$route.params.message) {
      this.$toasted.show(this.$route.params.message)
    }
  }
}
</script>
<style lang="scss" scoped>
.account-tabs {
  min-height: 610px;
}
</style>
